/**
 * Functions
 * @flow
 */
import { initAddresses } from '../reducers/addresses/addressReducer';
import { initContacts } from '../reducers/contacts/contactReducer';
import { initNames } from '../reducers/name/nameReducer';
import { POSTAGE } from '../data/Data';
import moment from 'moment';
import type { Application, AppState, GatewayVars, Transaction } from '../types/Types';

export const JEST_TIME = '2020-12-04 13:00:00';

type Props = {
  ...AppState,
  application: Application,
  bugsnagClient: any,
  cookies: boolean,
  digitalLicenceTerms: { value: boolean },
  terms: { value: boolean },
  jest: boolean,
  transaction: Transaction
};

export const Fn = {
  // format data for submission to the API
  formatData: (props: Props) => {
    const {
      application,
      addresses,
      club,
      contacts,
      cookies,
      dateOfBirth,
      dateOfTravel,
      deliveryMethod,
      deliveryPickUpStore,
      digitalLicenceTerms,
      email,
      licenceClass,
      licenceExpiry,
      licenceNumber,
      names,
      placeOfBirth,
      postage,
      stateOfIssue,
      wearGlasses,
      terms
    } = props;
    const { id, submission_token } = application;
    const { autoClub, membershipNumber } = club;
    const {
      licenceAddressStreet,
      licenceAddressLocality,
      licenceAddressState,
      licenceAddressPostcode,
      licenceAddressCountry,
      licenceDeliveryAddressStreet,
      licenceDeliveryAddressLocality,
      licenceDeliveryAddressState,
      licenceDeliveryAddressPostcode,
      licenceDeliveryAddressCountry
    } = addresses ? addresses : { ...initAddresses };
    const { phoneCountryCode, phoneNumber } = contacts ? contacts : { ...initContacts };
    const { firstName, lastName, licenceDeliveryAddressFullName, middleName } = names ? names : { ...initNames };

    let data = {
      address_street: licenceAddressStreet?.value,
      address_suburb: licenceAddressLocality?.value,
      address_state: licenceAddressState?.value,
      address_postcode: licenceAddressPostcode?.value,
      address_country: licenceAddressCountry?.value,
      customer: {
        email: email?.value,
        first_name: firstName?.value,
        middle_names: middleName?.value,
        last_name: lastName?.value,
        date_of_birth: dateOfBirth?.value,
        place_of_birth: placeOfBirth?.value,
        phone_country_code: phoneCountryCode?.value.replace(/\s+/g, ''),
        phone_number: phoneNumber?.value.replace(/\s+/g, ''),
      },
      club: application.club,
      cookies,
      delivery_name: licenceDeliveryAddressFullName?.value
        ? licenceDeliveryAddressFullName?.value
        : `${firstName?.value}${middleName?.value ? ` ${middleName?.value}` : ''} ${lastName?.value}`,
      delivery_street: licenceDeliveryAddressStreet?.value,
      delivery_suburb: licenceDeliveryAddressLocality?.value,
      delivery_state: licenceDeliveryAddressState?.value,
      delivery_postcode: licenceDeliveryAddressPostcode?.value,
      delivery_country: licenceDeliveryAddressCountry?.value,
      dl_terms_agreed: digitalLicenceTerms.value,
      glasses: wearGlasses?.value,
      id,
      intended_travel_date: dateOfTravel?.value,
      licence_class: licenceClass?.value,
      licence_expiry: licenceExpiry?.value,
      licence_number: licenceNumber?.value,
      member_club: autoClub && autoClub.value === 'NA' ? null : autoClub ? autoClub.value : null,
      membership_number: membershipNumber ? membershipNumber.value : '',
      postage: {
        ...postage,
        value:
          postage?.value !== POSTAGE[0].value && postage?.value !== POSTAGE[1].value && postage?.value !== POSTAGE[2].value
            ? 'dhl_express_international'
            : postage?.value
      },
      source: deliveryMethod?.value === 'PickUpInStore' ? 'in_store_pickup' : 'web',
      state_of_issue: stateOfIssue?.value,
      submission_token,
      tc_pp_agreed: terms.value,
    };

    if (deliveryMethod?.value === 'PickUpInStore' && deliveryPickUpStore?.value) {
      data.club_store_id = deliveryPickUpStore.value;
    }

    return data;
  },

  /**
   * Extract vars passed in URL from payment gateways
   */
  extractGatewayVars: (name: string | null, location: Location, jest: boolean = false): GatewayVars => {
    const { search } = location;
    const query = new URLSearchParams(search);

    switch (name) {
      case 'fatzebra': {
        const createdDateTime = jest ? JEST_TIME : moment().format('YYYY-MM-DD hh:mm:ss');
        const paymentAmount = query.get('chargeAmount'); // returned from gateway in cents
        const paymentReference = query.get('reference');
        const responseCode = query.get('r');
        const receiptNumber = query.get('id') ? query.get('id') : 'N/A';
        const responseDescription = query.get('message');
        const summaryCode = parseInt(responseCode);
        const hashCheck = query.get('v');
        const success = query.get('successful') === 'true' || summaryCode === 1 ? true : false;
        const cardToken = query.get('token');

        return {
          cardToken,
          createdDateTime,
          hashCheck,
          paymentAmount,
          paymentReference,
          receiptNumber,
          responseCode,
          responseDescription,
          summaryCode,
          success
        };
      }

      case 'ipsi': {
        const createdDateTime = jest ? JEST_TIME : moment().format('YYYY-MM-DD hh:mm:ss');
        const paymentAmount = query.get('amount');
        const paymentReference = query.get('reference') ? query.get('reference') : 'N/A';
        const responseCode = query.get('responseCode');
        const responseDescription = query.get('message');
        const receiptNumber = query.get('receipt') ? query.get('receipt') : 'N/A';
        const success = query.get('successful') === 'true' ? true : false;
        const summaryCode = parseInt(responseCode);

        return {
          createdDateTime,
          paymentAmount,
          paymentReference,
          receiptNumber,
          responseCode,
          responseDescription,
          success,
          summaryCode
        };
      }

      // eg: https://nrma.dev.idpfe.au1.ironstar.io/confirmation?settdate=20210201&expirydate=0222&callback_status_code=&restext=Approved&fingerprint=28f97fd0adb592bd31cfa80ac7c37eee7494549c&merchant=ABC0001&refid=12925&pan=444433...111&summarycode=1&rescode=00&txnid=222276&timestamp=20210201062120
      case 'nab-securepay': {
        let timestamp = query.get('timestamp'); // 20210210225831
        timestamp = timestamp
          ? `${timestamp.substr(0, 4)}-${timestamp.substr(4, 2)}-${timestamp.substr(6, 2)} ${timestamp.substr(
              8,
              2
            )}:${timestamp.substr(10, 2)}:${timestamp.substr(12, 2)}`
          : '';
        const createdDateTime = jest ? JEST_TIME : timestamp;
        const paymentAmount = query.get('chargeAmount');
        const paymentReference = query.get('refid');
        const responseCode = query.get('rescode');
        const receiptNumber = query.get('txnid') ? query.get('txnid') : 'N/A';
        const responseDescription = query.get('restext');
        const summaryCode = parseInt(query.get('summarycode'));
        const hashCheck = query.get('fingerprint');
        const success = summaryCode === 1 ? true : false;

        return {
          createdDateTime,
          hashCheck,
          paymentAmount,
          paymentReference,
          receiptNumber,
          responseCode,
          responseDescription,
          summaryCode,
          success
        };
      }

      case 'nab-transact': {
        let timestamp = query.get('payment_date');
        timestamp = timestamp
          ? `${timestamp.substr(0, 4)}-${timestamp.substr(4, 2)}-${timestamp.substr(6, 2)} ${timestamp.substr(
            8,
            2
          )}:${timestamp.substr(10, 2)}:${timestamp.substr(12, 2)}`
          : '';
        const createdDateTime = jest ? JEST_TIME : timestamp;
        const paymentAmount = query.get('payment_amount');
        const paymentReference = query.get('payment_reference');
        const responseCode = null;
        const receiptNumber = query.get('bank_reference');
        const responseDescription = null;
        const summaryCode = 0;
        const hashCheck = query.get('fingerprint');
        const success = null;

        return {
          createdDateTime,
          hashCheck,
          paymentAmount,
          paymentReference,
          receiptNumber,
          responseCode,
          responseDescription,
          summaryCode,
          success
        };
      }

      case 'westpac': {
        const createdDateTime = jest ? JEST_TIME : moment().format('YYYY-MM-DD hh:mm:ss');
        const hashCheck = query.get('hmac');
        const paymentAmount = query.get('paymentAmount');
        const paymentReference = query.get('paymentReference');
        const responseCode = query.get('responseCode');
        const receiptNumber = query.get('receiptNumber') ? query.get('receiptNumber') : 'N/A';
        const responseDescription = query.get('responseDescription');
        const summaryCode = parseInt(query.get('summaryCode'));
        const success = summaryCode === 0 ? true : false;

        return {
          createdDateTime,
          hashCheck,
          paymentAmount,
          paymentReference,
          receiptNumber,
          responseCode,
          responseDescription,
          summaryCode,
          success
        };
      }

      // http://ract.idp.local:3000/confirmation?sessionid=0000020012073653021d6fb80d9f0623
      case 'windcave': {
        const createdDateTime = jest ? JEST_TIME : moment().format('YYYY-MM-DD hh:mm:ss');
        const paymentAmount = query.get('chargeAmount');
        const paymentReference = query.get('sessionid');
        const responseCode = '00';
        const receiptNumber = query.get('sessionid');
        const responseDescription = 'Session ID returned';
        const summaryCode = 0;
        const success = null;

        return {
          createdDateTime,
          paymentAmount,
          paymentReference,
          receiptNumber,
          responseCode,
          responseDescription,
          summaryCode,
          success
        };
      }

      default:
        return {
          createdDateTime: null,
          paymentAmount: null,
          paymentReference: null,
          receiptNumber: null,
          responseCode: null,
          responseDescription: null,
          summaryCode: null,
          success: false
        };
    }
  },
  invertDate: (date: string) => {
    const re = new RegExp(/-/g);

    switch (true) {
      case date === '':
        return date;

      case !re.test(date):
        return date;

      default: {
        const parts = date.split('-');
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
      }
    }
  }
};

/**
 * count errors
 */
export const countErrors = (props: Props) => {
  let errors = 0;

  for (let field in props) {
    switch (field) {
      case 'validate':
        break;

      case 'addresses':
        for (let i in props[field]) {
          if (props[field][i]?.error) {
            errors++;
          }
        }
        break;

      case 'club':
        if (props[field]?.autoClub?.error) {
          errors++;
        }
        break;

      case 'contacts':
        if (props[field]?.contactNumber?.error) {
          errors++;
        }
        break;

      case 'images':
        for (let i in props[field]) {
          if (props[field][i]?.error) {
            errors++;
          }
        }
        break;

      case 'names':
        if (props[field]?.fullName?.error) {
          errors++;
        }
        if (props[field]?.firstName?.error) {
          errors++;
        }
        if (props[field]?.lastName?.error) {
          errors++;
        }
        break;

      default:
        if (props[field]?.error) {
          errors++;
        }
    }
  }

  return errors;
};
