/**
 * AAA IDP Payment page Footer
 * @flow
 */
import * as React from 'react';
import ErrorMessage from '../../../components/error-message/ErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { gateway } from '../../../actions/index';
import CreditCardForm from '../credit-card-form/CreditCardForm';
import IPSIframe from '../iframe/IPSIframe';
import { API, COSTS, OPTIONS, IS_PROD, gatewayForDomain } from '../../../data/Data';
import moment from 'moment';
import type { Application, ApiTokenResult, Gateway, Shipping, Transaction } from '../../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './Footer.css';

type Props = {
  application: Application,
  bugsnagClient: Bugsnag,
  jest: boolean,
  postage: Shipping,
  setGateway: (value: Transaction) => *,
  transaction: Transaction
};

type State = {
  apiError: boolean,
  apiErrorMessage: string,
  gateway: Gateway,
  process: boolean,
  spinner: boolean
};

export class Footer extends React.Component<Props, State> {
  // $FlowFixMe
  formRef: React.RefObject;

  static defaultProps = {
    application: {
      club: {
        abbreviation: 'RACV'
      },
      id: '1500',
      referrer: '',
      submission_token: 'f23re5et'
    },
    postage: {
      description: 'Express Post within Australia',
      cost: 11.40,
      value: 'express_post'
    },
    transaction: {
      community_code: null,
      gateway_url: null,
      merchant_id: null,
      paymentAmount: null,
      paymentReference: null,
      receiptNumber: null,
      reply_url: null,
      responseCode: null,
      responseDescription: null,
      summaryCode: null,
      success: false,
      token: null,
      tokenSuccess: null
    },
    jest: false
  };

  constructor(props: Props) {
    super(props);
    const { application, jest, postage } = props;
    const { id, submission_token } = application;
    const { host } = window.location;
    const amount = postage.cost + COSTS.permit;
    let gateway = !jest
      ? gatewayForDomain(host, id, submission_token, amount.toFixed(2), postage.description)
      : { name: '', method: '', redirectUrl: '' };

    if (gateway.name === 'ipsi') {
      gateway = this.ipsiParams(gateway);
    }

    this.state = {
      apiError: false,
      apiErrorMessage: '',
      gateway,
      process: false,
      spinner: true
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    // get token from API for redirect to payment
    this.submit();
  }

  componentWillUnmount() {
    clearTimeout();
  }

  /**
   * Process
   */
  process = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    this.submitForm();
  };

  /**
   * submit
   */
  submit = () => {
    const { application } = this.props;
    const { gateway } = this.state;
    const { name, redirectUrl } = this.state.gateway;
    const { params } = this.ipsiParams(gateway);
    const { id, submission_token } = application;
    const data =
      name === 'ipsi'
        ? {
            submission_token,
            redirect_url: redirectUrl,
            params
          }
        : {
            submission_token,
            redirect_url: redirectUrl
          };

    const options = {
      ...OPTIONS,
      method: 'POST',
      body: JSON.stringify(data)
    };
    const url = `${API}/${id}/payment/${name}/token`;

    this.setState({
      apiError: false,
      spinner: true
    });

    fetch(url, options)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn('Payment Page Footer failed API call', result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(result => this.success(result))
      .catch(err => this.error(err));
  };

  /**
   * IPSI params
   */
  ipsiParams = (gateway: Gateway) => {
    const { application, jest, postage } = this.props;
    const { id } = application;
    const amount = (COSTS.permit + postage.cost).toFixed(2);
    const name = IS_PROD ? 'idp.iframe' : 'RAA.Travel.IDP.iframe.demo';
    const configId = IS_PROD ? '3b0450ba-44a9-426a-aa46-b4b1a75ce71d' : '0409559a-f412-4350-a3a7-049e93689c1d';
    const search = new URLSearchParams();
    const timestamp = jest ? '1623294796806' : moment().format('X');
    search.set('amount', amount);
    search.set('currency', 'AUD');
    search.set('txnType', '0');
    search.set('userName', name);
    search.set('configId', configId);
    search.set('merchReference', `${id}-${timestamp ? timestamp : ''}`);

    gateway = {
      ...gateway,
      params: search.toString(),
      timestamp
    };
    return gateway;
  };

  /**
   * success posting to API
   */
  success = (result: ApiTokenResult) => {
    const { setGateway } = this.props;
    const { id } = this.props.application;
    const { community_code, gateway_url, reply_url, success, merchant_id, paymentReference, timestamp, token } = result
      ? result
      : {
          community_code: '',
          gateway_url: '',
          merchant_id: null,
          paymentReference: '',
          reply_url: '',
          timestamp: null,
          success: false,
          token: ''
        };
    const haveVars = this.haveGatewayVars(result);

    // handle API validation errors
    if (!success || !haveVars) {
      const error = new Error();
      console.warn('Error posting to API', result);
      this.error(error, `Payment gateway error for application #${id}`);
      return false;
    }
    setGateway({ community_code, gateway_url, reply_url, merchant_id, paymentReference, timestamp, token });
    this.setState(
      {
        apiError: false,
        process: true
      },
      () => this.submitForm()
    );
  };

  /**
   * Error
   */
  error = (err: Error, message: string = '') => {
    const { bugsnagClient, jest } = this.props;

    if (jest) return null;

    console.warn(err);
    bugsnagClient.notify(err);

    this.setState({
      apiError: true,
      apiErrorMessage: message ? message : err.message
    });
  };

  /**
   * Redirect by submitting the hidden form
   * Works for westpac and NAB Transact
   */
  submitForm = () => {
    const { abbreviation } = this.props.application.club;
    const haveVars = this.haveGatewayVars(null);

    switch (true) {
      case !this.formRef || !this.formRef.current || !haveVars:
        return null;

      case abbreviation === 'AANT':
      case abbreviation === 'RACV':
        setTimeout(() => {
          this.formRef.current.submit();
        }, 2000); // delay added for UX
        break;

      default:
      // no op
    }
  };

  /**
   * Do we have the correct gateway vars to redirect?
   */
  haveGatewayVars = (result: ApiTokenResult | null) => {
    const abbr = this.props.application.club.abbreviation;
    let { community_code, gateway_url, merchant_id, timestamp, token } = result ? result : this.props.transaction;

    switch (true) {
      case abbr === 'AANT' && community_code !== null && gateway_url !== null && token !== null:
      case abbr === 'RAA' && gateway_url !== null && token !== null:
      case abbr === 'RAC' && gateway_url !== null && token !== null:
      case abbr === 'RACQ' && gateway_url !== null && token !== null:
      case abbr === 'RACV' && gateway_url !== null && token !== null && merchant_id !== null:
      case abbr === 'RACT' && gateway_url !== null && token !== null:
      case abbr === 'NRMA' && gateway_url !== null && token !== null && timestamp !== null && merchant_id !== null:
        return true;

      default:
        return false;
    }
  };

  renderRedirectUI() {
    const { community_code, gateway_url, token } = this.props.transaction;
    const { apiError, spinner, process } = this.state;

    return (
      <React.Fragment>
        <form ref={this.formRef} action={gateway_url}>
          <input type="hidden" name="communityCode" value={community_code ? community_code : ''} />
          <input type="hidden" name="token" value={token ? token : ''} />
        </form>
        <p>
          <FontAwesomeIcon icon="triangle-exclamation" className="left" />
          <strong>
            Please don't use the browser's back button. Press{' '}
            <Link className="cancel" to="/summary">
              Cancel Payment
            </Link>{' '}
            if you would like to go back to the previous screen.
          </strong>
        </p>
        <div className="footer actions">
          <Link className="button cancel reverse" to="/summary">
            <FontAwesomeIcon icon="xmark" />
            Cancel Payment
          </Link>
          <div
            className={`button process${gateway_url ? '' : ' disabled'}`}
            onClick={this.process}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={apiError ? 'triangle-exclamation' : spinner ? 'spinner' : 'check'} />
            {!process ? <span>Process Order</span> : <span>Redirecting</span>}
          </div>
        </div>
        <div className="patience">
          <p>
            Please wait while you are redirected to the payment server. If nothing happens within 15 seconds, please
            click on the{' '}
            <span
              className={`link process${gateway_url ? '' : ' disabled'}`}
              onClick={this.process}
              role="button"
              tabIndex="0"
            >
              Process Order
            </span>{' '}
            button above.
          </p>
        </div>
      </React.Fragment>
    );
  }

  renderRedirectToNabTransact() {
    const { postage, transaction } = this.props;
    const { gateway_url, merchant_id, paymentReference, reply_url, token } = transaction;
    let { redirectUrl } = this.state.gateway;
    const { apiError, spinner, process } = this.state;

    redirectUrl = redirectUrl +
      '&bank_reference=&payment_reference=&payment_amount=&payment_number=&payment_date=&fingerprint=';
    console.log(postage.description, postage.cost.toFixed(2))

    return (
      <React.Fragment>
        <form ref={this.formRef} action={gateway_url}>
          <input type="hidden" name="fingerprint" value={token ? token : ''} />
          <input type="hidden" name="vendor_name" value={merchant_id ? merchant_id : ''} />
          <input type="hidden" name="payment_reference" value={paymentReference ? paymentReference : ''} />
          <input type="hidden" name="return_link_url" value={redirectUrl ? redirectUrl : ''} />
          <input type="hidden" name="reply_link_url" value={reply_url ? reply_url : ''} />

          <input type="hidden" name="International Driving Permit" value={COSTS.permit.toFixed(2)} />
          {postage.cost > 0 && <input type="hidden" name="Postage" value={postage.cost.toFixed(2)} />}
        </form>
        <p>
          <FontAwesomeIcon icon="triangle-exclamation" className="left" />
          <strong>
            Please don't use the browser's back button. Press{' '}
            <Link className="cancel" to="/summary">
              Cancel Payment
            </Link>{' '}
            if you would like to go back to the previous screen.
          </strong>
        </p>
        <div className="footer actions">
          <Link className="button cancel reverse" to="/summary">
            <FontAwesomeIcon icon="xmark" />
            Cancel Payment
          </Link>
          <div
            className={`button process${gateway_url ? '' : ' disabled'}`}
            onClick={this.process}
            role="button"
            tabIndex="0"
          >
            <FontAwesomeIcon icon={apiError ? 'triangle-exclamation' : spinner ? 'spinner' : 'check'} />
            {!process ? <span>Process Order</span> : <span>Redirecting</span>}
          </div>
        </div>
        <div className="patience">
          <p>
            Please wait while you are redirected to the payment server. If nothing happens within 15 seconds, please
            click on the{' '}
            <span
              className={`link process${gateway_url ? '' : ' disabled'}`}
              onClick={this.process}
              role="button"
              tabIndex="0"
            >
              Process Order
            </span>{' '}
            button above.
          </p>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Render iFrame for FatZebra Direct Post
   */
  renderFatZebraIframe() {
    const { token, gateway_url } = this.props.transaction;

    if (!token || !gateway_url) return null;

    const { redirectUrl } = this.state.gateway;
    const params = new URLSearchParams();
    params.set('iframe', 'true');
    params.set('show_extras', 'false');
    params.set('show_email', 'false');
    params.set('masterpass', 'false');
    params.set('visacheckout', 'false');
    params.set('tokenize_only', 'true');
    params.set('return_path', redirectUrl);
    const src = `${gateway_url}?${params.toString()}`;

    return <iframe src={src} title="Payment iFrame" />;
  }

  render() {
    const { application, bugsnagClient, postage, transaction } = this.props;
    const { apiError, apiErrorMessage, gateway } = this.state;
    const { name, method } = gateway;
    let content = null;

    switch (true) {
      case name === 'fatzebra':
        content = this.renderFatZebraIframe();
        break;

      case name === 'ipsi':
        content = (
          // $FlowFixMe
          <IPSIframe
            application={application}
            bugsnagClient={bugsnagClient}
            gateway={gateway}
            postage={postage}
            transaction={transaction}
          />
        );
        break;

      case name === 'nab-transact':
        content = this.renderRedirectToNabTransact();
        break;

      case method === 'form':
        content = <CreditCardForm bugsnagClient={bugsnagClient} formRef={this.formRef} gateway={gateway} />;
        break;

      default:
        content = this.renderRedirectUI();
    }

    return (
      <div className="Footer">
        {content}
        <ErrorMessage error={apiError} message={apiErrorMessage} />
      </div>
    );
  }
}

const mapStateToProps = ({ application, postage, transaction }) => {
  return { application, postage, transaction };
};

const mapDispatchToProps = dispatch => {
  return {
    setGateway: (value: Transaction) => {
      dispatch(gateway(value));
    }
  };
};

const VisibleFooter = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default VisibleFooter;
