/**
 * AAA IDP delivery method
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { deliveryMethod, deliveryPickUpStoreRequiredAttribute, deliveryAddressRequiredAttribute, postage } from '../../actions/index';
import {
  DELIVERY_METHOD_POSTAGE,
  DELIVERY_METHOD_PICK_UP_IN_STORE,
  STORES_API, POSTAGE
} from '../../data/Data';
import type { ApiResult, Application, AppState, Field, Shipping } from '../../types/Types';
import './DeliveryMethod.css';
import DeliveryMethodPostage from '../delivery-method-postage/DeliveryMethodPostage';
import DeliveryMethodPickUpInStore from '../delivery-method-pick-up-in-store/DeliveryMethodPickUpInStore';
import ErrorMessage from '../error-message/ErrorMessage';

type Props = {
  ...AppState,
  application: Application,
  deliveryMethod: Field,
  setDeliveryAddressRequiredAttribute: (required: boolean) => *,
  setDeliveryMethod: (value: string) => *,
  setDeliveryPickUpStoreRequiredAttribute: (required: boolean) => *,
  setPostage: (postage: Shipping) => *,
};

export class DeliveryMethod extends React.Component<Props, State> {
  static defaultProps = {
    deliveryMethod: {
      error: false,
      required: true,
      valid: true,
      value: ''
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: '',
      clubStores: [],
    };
  }

  componentDidMount() {
    this.getStores();
  }

  handleSelectionChange = (value: string): void => {
    const { setDeliveryMethod, setPostage, setDeliveryPickUpStoreRequiredAttribute, setDeliveryAddressRequiredAttribute } = this.props;

    setDeliveryMethod(value);

    if (value === DELIVERY_METHOD_PICK_UP_IN_STORE.value) {
      setPostage(POSTAGE[2]);
      setDeliveryPickUpStoreRequiredAttribute(true);
      setDeliveryAddressRequiredAttribute(false);
    }
    else {
      setPostage(POSTAGE[0]);
      setDeliveryPickUpStoreRequiredAttribute(false);
      setDeliveryAddressRequiredAttribute(true);
    }
  }

  /**
   * onFocus
   */
  onFocus = (focus: string) => {
    this.handleSelectionChange(focus);

    this.setState({
      focus
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: ''
    });
  };

  /**
   * Select Postage / Pick up in-store
   */
  select = (value: string) => {
    this.handleSelectionChange(value);
  };

  getStores = () => {
    const { application } = this.props;
    const { club } = application;
    fetch(`${STORES_API}/clubs/${club.id}/stores`)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(results => this.success(results))
      .catch(error => this.error(error));
  };

  success = (result: ApiResult) => {
    if (!result) {
      this.handleSelectionChange('Postage');
      return null;
    }

    if ((result.errors && result.message) || !result.data) {
      const message = result.message ? result.message : 'No data returned from API';
      this.error(new Error(message));
      this.handleSelectionChange('Postage');
      return;
    }

    if (result.data.length === 0) {
      this.handleSelectionChange('Postage');
    }

    this.setState({
      clubStores: result.data,
    });
  };

  error = (error: Error) => {
    const { bugsnagClient, jest } = this.props;

    if (jest) return null;

    console.warn(error);

    this.setState({
      error: true,
      submitted: false,
      submitting: false
    });

    bugsnagClient.notify(error);
  };

  render() {
    const { deliveryMethod } = this.props;
    let errorMessage = deliveryMethod.error ? 'A delivery method must be selected.' : '';

    let buttons = [];
    const options = [
      {
        default: true,
        checked: deliveryMethod.value === DELIVERY_METHOD_POSTAGE.value,
        label: DELIVERY_METHOD_POSTAGE.label,
        description: DELIVERY_METHOD_POSTAGE.description,
        value: DELIVERY_METHOD_POSTAGE.value
      },
      {
        default: false,
        checked: deliveryMethod.value === DELIVERY_METHOD_PICK_UP_IN_STORE.value,
        label: DELIVERY_METHOD_PICK_UP_IN_STORE.label,
        description: DELIVERY_METHOD_PICK_UP_IN_STORE.description,
        value: DELIVERY_METHOD_PICK_UP_IN_STORE.value
      }
    ];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const highlight = option.checked ? ' highlight' : '';

      let temporaryNotice = null;
      if (option.value === DELIVERY_METHOD_PICK_UP_IN_STORE.value) {
        temporaryNotice = <div className="notice">In-store pickup has a limited number of pickup locations</div>;
      }
      buttons.push(
        <div
          className={'radiobutton-container' + highlight}
          key={option.value}
          tabIndex="0"
          onBlur={() => this.onBlur}
          onFocus={() => this.onFocus(option.value)}
        >
          {temporaryNotice}
          <input
            id={option.value}
            className="radio-button-hidden"
            type="radio"
            checked={option.checked}
            value={option.value}
            onChange={() => this.select(option.value)}
          />
          <label htmlFor={option.value}>
            <div className="radio-display">
              <div className={`inner${highlight}`} />
            </div>
            <span>{option.label}</span>
          </label>
          <span dangerouslySetInnerHTML={{ __html: option.description }}></span>
        </div>
      );
    }

    if (this.state.clubStores.length > 0 && buttons.length > 0) {
      return (
        <div>
          <div className="form-element-container DeliveryMethod">
            <div className="delivery-options">
              <div className="label title">Select delivery method:</div>
              <div className="radio-buttons">{buttons}</div>
            </div>
          </div>
          {deliveryMethod.value === 'Postage' && <DeliveryMethodPostage />}
          {deliveryMethod.value === 'PickUpInStore' && (
            <DeliveryMethodPickUpInStore clubStores={this.state.clubStores} />
          )}
          <ErrorMessage message={errorMessage} error={deliveryMethod.error} />
        </div>
      );
    } else {
      return <DeliveryMethodPostage />;
    }
  }
}

const mapStateToProps = ({ application, deliveryMethod }) => {
  return { application, deliveryMethod };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryMethod: (value: string) => {
      dispatch(deliveryMethod(value));
    },
    setDeliveryPickUpStoreRequiredAttribute: (required: boolean) => {
      dispatch(deliveryPickUpStoreRequiredAttribute(required));
    },
    setDeliveryAddressRequiredAttribute: (required: boolean) => {
      dispatch(deliveryAddressRequiredAttribute(required));
    },
    setPostage: (value: Shipping) => {
      dispatch(postage(value));
    }
  };
};

const VisibleDeliveryMethod = connect(mapStateToProps, mapDispatchToProps)(DeliveryMethod);

export default VisibleDeliveryMethod;
