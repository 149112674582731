/**
 * AAA IDP Postage component
 * @flow
 */
import * as React from 'react';
import { IMAGE_PATH_PREFIX, FIELDS, POSTAGE, STARSHIPIT } from '../../../data/Data';
import RadioButton from '../../../components/radio-button/RadioButton';
import { connect } from 'react-redux';
import { postage } from '../../../actions';
import type { Addresses, Shipping } from '../../../types/Types';
import './Postage.css';

type Props = {
  addresses: Addresses,
  bugsnagClient: { notify: Error => * },
  jest: boolean,
  postage: Shipping,
  setPostage: (postage: Shipping) => *,
  setCanPay: (canPay: boolean) => *
};

type State = {
  rates: Array<Shipping>
};

type Response = {
  errors?: Array<{
    details: string,
    message: string
  }>,
  rates?: Array<{
    service_name: string,
    service_code: string,
    total_price: number
  }>,
  success: boolean
};

export class Postage extends React.Component<Props, State> {
  static defaultProps = {
    addresses: {
      ...FIELDS.addresses,
      licenceDelivery: 'International'
    },
    jest: false,
    postage: {
      value: 'express_au',
      description: 'Express Post within Australia',
      cost: 11.40
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      rates: [...POSTAGE]
    };
  }

  componentDidMount() {
    this.getStarShipIt();
  }

  /**
   * Get StarShipIt rates for international delivery
   */
  getStarShipIt = () => {
    const { addresses, jest, setCanPay } = this.props;
    const {
      licenceDelivery,
      licenceDeliveryAddressStreet,
      licenceDeliveryAddressLocality,
      licenceDeliveryAddressState,
      licenceDeliveryAddressCountry,
      licenceDeliveryAddressPostcode
    } = addresses;

    switch (true) {
      case jest:
      case licenceDelivery === 'Australian':
        return null;

      case licenceDeliveryAddressStreet.value === '':
        console.warn('Skipping StarShipIt lookup. Missing delivery street');
        return null;

      case licenceDeliveryAddressCountry.value === '':
        console.warn('Skipping StarShipIt lookup. Missing delivery country');
        return null;

      default:
      // no op
    }

    let vars = {};
    let queryVars = '?';

    vars.street = licenceDeliveryAddressStreet.value;
    vars.country_code = licenceDeliveryAddressCountry.value;
    vars.post_code = licenceDeliveryAddressPostcode.required ? licenceDeliveryAddressPostcode.value : '00000';
    vars.weight = 0.5;

    if (licenceDeliveryAddressLocality.value) {
      vars.city = licenceDeliveryAddressLocality.value;
    }

    if (licenceDeliveryAddressState.required) {
      vars.state = licenceDeliveryAddressState.value;
    }

    for (const key in vars) {
      const join = key === 'street' ? '' : '&';
      queryVars = `${queryVars}${join}${key}=${vars[key]}`;
    }

    // Substitute `#` for `%23` as Starship returns 404 otherwise
    const pound = new RegExp('#', 'ig');
    queryVars = queryVars.replace(pound, '%23');

    const url = encodeURI(`${STARSHIPIT.url}${queryVars}`);

    const options = {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'StarShipIT-Api-Key': STARSHIPIT.apiKey,
        'Ocp-Apim-Subscription-Key': STARSHIPIT.primaryKey
      },
      redirect: 'error',
      referrerPolicy: 'no-referrer',
      data: '{body}'
    };

    fetch(url, options)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(response => {
        this.success(response, url);
        setCanPay(true);
      })
      .catch(error => {
        this.error(error, url);
        setCanPay(true);
      });

    return url;
  };

  /**
   * Success StarShipit
   */
  success = (response: Response, url: string) => {
    // case: error, or success but no rates provided
    if (!response.success || !response.rates || response.rates.length === 0) {
      const error = new Error(`Error fetching starShipIt rates with ${url} response: ${JSON.stringify(response)}`);
      this.error(error, url);
      return;
    }

    let { rates } = this.state;

    for (let i = 0; i < response.rates.length; i++) {
      const rate = response.rates[i];
      const cost = Math.round(rate.total_price * 100 + Number.EPSILON) / 100;

      rates.push({
        value: 'dhl_express_international',
        description: rate.service_name,
        cost
      });
    }

    this.setState({
      rates
    });

    // set shipping to DHL
    this.setShipping(rates[rates.length - 1].value);
  };

  /**
   * Error on starship it API
   */
  error = (error: Error, url: string) => {
    let { rates } = this.state;

    console.warn(error, url);

    // dispatch to BugSnag
    this.props.bugsnagClient.notify(error);

    // set shipping to AusPost international express
    this.setShipping(rates[1].value);
  };

  /**
   * Get shipping
   */
  getShipping = () => {
    const { addresses, postage } = this.props;
    const { licenceDelivery } = addresses;
    let { value } = postage;
    const { rates } = this.state;

    for (let i = 0; i < rates.length; i++) {
      const rate = rates[i];
      if (rate.value === value) {
        return rate;
      }
    }

    // Fall back
    if (licenceDelivery === 'International') {
      return rates[1];
    }

    return rates[0];
  };

  /**
   * setShipping
   * only runs for international
   */
  setShipping = (value: string) => {
    const { setPostage } = this.props;
    const rate = this.getRate(value);

    setPostage(rate);
  };

  /**
   * getRate
   */
  getRate = (value: string) => {
    const { rates } = this.state;

    for (let i = 0; i < rates.length; i++) {
      const rate = rates[i];
      if (value === rate.value) {
        return rate;
      }
    }

    return rates[1]; // defaults to 'express_int'
  };

  render() {
    const { addresses, postage } = this.props;
    const { value } = postage;
    const { rates } = this.state;
    const options = [];

    if (addresses.licenceDelivery === 'Australian') return null;
    // Start loop from 1 to exclude domestic post
    for (let i = 1; i < rates.length; i++) {
      const rate = rates[i];
      // Exclude options with $0 cost, i.e. in-store pickup
      if (rate.cost && rate.cost > 0) {
        const checked = value === rate.value ? true : false;
        const title = (
          <React.Fragment>
            <div className="description">{rate.description}</div>
            <div className="price">
              <span>AUD</span> <span>${rate.cost?.toFixed(2)}</span>
            </div>
          </React.Fragment>
        );
        const icon =
          i === 1 ? (
            <img src={`${IMAGE_PATH_PREFIX}AustraliaPost_2019-hori.png`} alt="Australia Post logo" />
          ) : (
            <img src={`${IMAGE_PATH_PREFIX}DHL_Logo.svg`} alt="DHL logo" />
          );

        options.push({
          default: i === 1 ? true : false,
          checked,
          title,
          value: rate.value,
          icon
        });
        options.reverse();
      }
    }

    return (
      <div className="Postage">
        <RadioButton
          label="Please select your preferred International Shipping"
          name="postage"
          handleChange={this.setShipping}
          onBlur={this.setShipping}
          options={options}
          // $FlowFixMe
          values={{ postage }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ addresses, postage }) => {
  return { addresses, postage };
};

const mapDispatchToProps = dispatch => {
  return {
    setPostage: (value: Shipping) => {
      dispatch(postage(value));
    }
  };
};

const VisiblePostage = connect(mapStateToProps, mapDispatchToProps)(Postage);

export default VisiblePostage;
